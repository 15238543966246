import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PreAuthGuard} from './shared/guards/pre-auth.guard';
import {AuthGuard} from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canLoad: [PreAuthGuard]
  },
  {
    path: 'aplicativos',
    loadChildren: () => import('./pages/aplicativos/aplicativos.module')
      .then(m => m.AplicativosPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/usuarios/usuarios.module')
      .then(m => m.UsuariosPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'modal-controle-versao',
    loadChildren: () => import('./modals/modal-controle-versao/modal-controle-versao.module')
      .then(m => m.ModalControleVersaoPageModule)
  },
  {
    path: 'modal-usuarios-vinculados',
    loadChildren: () => import('./modals/modal-usuarios-vinculados/modal-usuarios-vinculados.module')
      .then(m => m.ModalUsuariosVinculadosPageModule)
  },
  {
    path: 'modal-grupo',
    loadChildren: () => import('./modals/modal-grupo/modal-grupo.module')
      .then(m => m.ModalGrupoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

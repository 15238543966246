import {Injectable} from '@angular/core';
import {CanLoad, Route, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PreAuthGuard implements CanLoad {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
  }

  async canLoad(route: Route): Promise<boolean> {
    const isAuthenticated = await this.auth.isAuthenticated();
    if (isAuthenticated) {
      await this.router.navigate(['/aplicativos']);
      return false;
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  jwtHelper = new JwtHelperService();

  constructor(
    private authService: AuthService,
    private router: Router,
    private toast: ToastService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (!this.isUrlAberta(request.url) && token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && this.router.routerState.snapshot.url !== '/login') {
          this.authService.clearAuthorizationToken();
          this.toast.toastDark('Sessão expirada, por favor, realize login novamente.');
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }

  private isUrlAberta(url: string) {
    return url.endsWith('v1/localidade/ufs') || url.indexOf('v1/localidade/municipios') !== -1 || url.endsWith('v1/usuario/perfis');
  }

  isTokenExpired(token) {
    if (!token) {
      return false;
    }

    return this.jwtHelper.isTokenExpired(token);
  }
}

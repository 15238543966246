import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Config} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

export const TOKEN_AUTH = '_integrador_tk_Auth';
export const USER_DATA = '_integrador_user_data';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  jwtHelper = new JwtHelperService();
  private user$: Subject<boolean> = new Subject<boolean>();

  constructor(public http: HttpClient) {
  }

  async isAuthenticated() {
    const token = this.getToken();
    if (token == 'null') {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  login(username: string, senha: string, code: string = null) {
    const headers = new HttpHeaders({'Access-Control-Expose-Headers': 'Authorization'});
    const options: any = {headers, observe: 'response'};
    return this.http.post<Config>(`${environment.apiUrl}/login`, {
      username,
      senha,
      code
    }, options)
      .pipe(map(
        (response: any) => {
          if (response instanceof HttpResponse &&
            response.body.origem !== 'GOV.BR' && response.body.id !== null) {
            const token = response.headers.get('Authorization').split(' ')[1];
            this.setUser(response.body);
            this.setToken(token);
          }
          return response.body;
        }
        )
      );
  }

  loggedIn() {
    this.user$.next(true);
  }

  isLoggedIn() {
    return this.user$;
  }

  preLogin(nuCpf: string, password: string): Promise<any> {
    return this.http.post(`${environment.apiUrl}/auth/pre-login`, {username: nuCpf, password}).toPromise();
  }

  ativarUsuario(nuCpf: string, password: string) {
    return this.http.post(`${environment.apiUrl}/usuario/ativar`, {username: nuCpf, password}).toPromise();
  }

  setUserAsync(usuario, perfil) {
    const user = this.getUser();
    user.username = usuario.cpf;
    user.nome = usuario.nome;
    user.perfil.codigo = perfil.id;
    user.perfil.descricao = perfil.nome;
    this.setUser(user);
  }

  getUserAsync() {
    return this.user$.asObservable();
  }

  setUser(user: any) {
    if (user) {
      localStorage.setItem(USER_DATA, JSON.stringify(user));
      this.user$.next(user);
    }
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem(USER_DATA));
  }

  setToken(token: any) {
    return localStorage.setItem(TOKEN_AUTH, token);
  }

  getToken() {
    return localStorage.getItem(TOKEN_AUTH);
  }

  clearAuthorizationToken(): void {
    localStorage.removeItem(USER_DATA);
    // this.setUser(null);
    localStorage.removeItem(TOKEN_AUTH);
  }
}

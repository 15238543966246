import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Aplicativos', url: '/folder/Aplicativos', icon: 'mail' },
    { title: 'Usuários', url: '/folder/Usuarios', icon: 'paper-plane' },
  ];
  constructor() {}
}

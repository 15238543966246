import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController) {
  }

  async toast(message: string | any, color?: string, position: 'top' | 'bottom' | 'middle' = 'bottom',
              duration: number = 3000, cssClass: string = 'toast-class') {
    const toast = await this.toastController.create({
      message,
      duration,
      color,
      position,
      keyboardClose: true,
      animated: true,
      cssClass
    });
    toast.present();
  }

  toastSuccess(message: string) {
    this.toast(message, 'success');
  }

  toastError(message: string) {
    this.toast(message, 'danger');
  }

  toastWarning(message: string) {
    this.toast(message, 'warning');
  }

  toastDark(message: string) {
    this.toast(message, 'dark');
  }
}
